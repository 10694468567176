@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@500;700&family=Nunito&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&family=Varela+Round&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@500&display=swap");
* {
  font-family: "Cairo";
  box-sizing: border-box;
}
body {
  font-family: "Cairo", sans-serif;
  margin: 0px !important;
}

.summary-card {
  height: 77px;
}

body::-webkit-scrollbar {
  width: 0px;
}

.input-field {
  height: 56px;
  background-color: white;
  border-radius: 100px;
}
.text-area {
  height: 213px;
  width: 585px;
  background-color: white;
}

.height-otp {
  margin-top: 30px !important;
  margin-left: 50px !important;
}
.custom-calender {
  border: none !important;
  background: rgba(255, 255, 255, 1) !important;
  margin-right: 0px !important;
  padding: 0 3px 3px 3px;
}

/* .react-calendar__navigation button {
  min-width: 95px !important;
} */

.react-calendar__navigation__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;

  font-weight: 400 !important;
  color: rgba(192, 191, 193, 1);
}
.react-calendar__navigation__label {
  font-family: "Cairo";
  font-size: 16px;
  color: rgba(45, 44, 48, 1);
  width: 40px !important;
  margin: 0;
}
.custom-calender .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
  font-family: "Cairo";
  font-weight: 600 !important;
  letter-spacing: 1px;
}
.react-calendar {
  height: 297px;
  overflow: hidden;
  margin-right: 0px !important;
  border: none !important;
  border-radius: 16px;
  font-family: "Cairo", sans-serif;
  text-decoration: none;
  width: auto !important;
}
abbr[title] {
  text-decoration: none !important;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: none !important;
}
.react-calendar__tile:disabled {
  background-color: white !important;
}
.react-calendar__tile abbr {
  width: 28px !important;
  height: 27px !important;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-calendar__month-view__weekdays {
  text-transform: capitalize !important;
  font-family: "Cairo";
}
.react-calendar__tile abbr:hover {
  background-color: #e6e6e6;
  border-radius: 50%;
}
.react-calendar__tile {
  padding: 8px 7px 7px 13px !important;
}
.react-calendar__tile--now {
  background-color: transparent !important;
  color: inherit !important;
}

.react-calendar__tile--active abbr {
  padding: 3px;
  background: rgba(8, 79, 140, 1) !important;
  color: white !important;
  border-radius: 50%;
}
/* .custom-calendar .react-calendar__month-view__days__day abbr {
  color: blue !important;
} */
/* button.react-calendar__tile.react-calendar__month-view__days__day {
  color: rgba(192, 191, 193, 1);
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #ededed !important;
} */

.react-calendar__tile--hasActive {
  background: pink !important;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #cdcdcd;
}

.react-calendar__navigation {
  background: white;
  color: #ffffff;
  font-weight: bold;
  border-radius: 10px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: white;
}

.calendar-grid-item {
  padding: 0px !important;
}

.calendar-grid-item3 {
  padding: 0 0 0 0 !important;
}

.grid3 {
  min-width: 370px !important;
  padding: 0px !important;
  margin: 0px 0px 0px 5px;
}
.react-calendar__tile--active {
  background-color: white !important;
  color: #ffffff;
}
.chip-radius {
  border-radius: "0px" !important;
}

.active-chip {
  background-color: grey;
  color: white;
}

.css-r8fodm {
  margin: 0px;
}

.service-card-img {
  background: #ffffff;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  top: -35px;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  display: flex;
  margin-bottom: 3px;
  justify-content: center;
  align-items: center;
}
.service-card-img2 {
  background: #ffffff;
  width: 70px !important;
  height: 70px !important;
  border-radius: 50%;
  position: absolute;
  top: -35px;
  left: 50%;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  margin-bottom: 3px;
  justify-content: center;
  align-items: center;
  object-fit: contain !important;
}
.service-card-img img {
  width: 50px;
  height: 40px;
}
.summary-card-img {
  width: 44px;
  height: 44px;
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.summary-card-img img {
  width: 30px;
  height: 30px;
}
.receipt {
  width: 33.3333%;
  padding: 16px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: none !important;
}

.css-vyoeq7-MuiPaper-root-MuiDialog-paper {
  max-width: 375px !important;
}
.css-1nhw3lq-MuiStack-root > :not(style) + :not(style) {
  margin-top: 0px !important;
}
.css-a5rdam {
  margin: 40px 0px 20px 0px !important;
}
.css-1xaekgw {
  margin: 10px 0px 20px 0px !important;
}
.css-1yo5kz1-MuiGrid-root {
  margin-top: 50px;
}
.css-1u19dx9-MuiStack-root {
  margin-top: 0px !important;
}
.marginzero {
  margin: 13px 0px 30px 0px !important;
}
.marginzero1 {
  margin: 0px 0 30px 0 !important;
}
.css-mju1p9-MuiFormLabel-root-MuiInputLabel-root {
  right: 10px;
  left: 0px;
}
.css-xrswcz-MuiFormLabel-root-MuiInputLabel-root {
  left: 10%;
}
.textarea-icon {
  padding-left: 20px !important;
}
.textareaicon1 {
  left: "3%";
}
.textareaicon2 {
  right: "3%";
  padding-left: "1%";
}
textarea.textboxx {
  padding-top: 35px;
  padding-left: 59px;
}

textarea.textboxx2 {
  padding-top: 35px;
  padding-right: 59px;
}
.textareainput {
  left: 0 !important;
  padding-left: 46px !important;
}
.textareainput2 {
  right: 0 !important;
  padding-right: 60px !important;
  left: unset !important;
  margin-right: 10px;
}
.textboxx .css-1w7pgwv-MuiFormLabel-root-MuiInputLabel-root {
  right: 0 !important;
  left: unset;
  padding-right: 65px !important;
  margin-right: 10px;
}
.text-icon {
  padding-top: 13px !important;
  padding-left: 21px !important;
  padding-right: 14px !important;
}
.text-icon2 {
  padding-top: 13px !important;
  padding-right: 21px !important;
  padding-left: 14px !important;
}

.reviewbtn1 {
  margin-bottom: 10px !important;
}
.verifygrid .margin50 {
  margin-bottom: 10px !important;
}

.margin-b {
  margin-bottom: 44px !important;
}
.label-input {
  font-size: 12px !important;
  margin-left: 13px;
  padding-left: 19px !important;
}

.input-label1 {
  left: unset !important;
  right: 63px !important;
  direction: rtl !important;
  line-height: 1.3 !important;
  padding: 0px 0px 2px 0px !important;
}
.input-label {
  left: 48px !important;
  direction: ltr !important;
  top: 6px !important;
  padding: 0px 0px 3px 0px !important;
}
.input-fieldd .css-1j6omf3 {
  padding-top: 28px !important;
  padding-right: 0px !important;
  height: 1em !important;
}
.input-fielddar .css-1j6omf3 {
  padding-top: 30px !important;
  padding-right: 0px !important;
  height: 1em !important;
  padding-left: 50% !important;
  padding-right: 0px !important;
  direction: ltr !important;
  padding-bottom: 16px !important;
}
.input-fielddar .css-1vbc0rj-MuiInputBase-input-MuiFilledInput-input {
  padding-top: 28px !important;
  padding-right: 35px !important;
  padding-left: 50%;
  height: 1em !important;
  padding-bottom: 16px !important;
text-align: right;
  direction: ltr !important;
}
.input-fieldd3 .css-1j6omf3 {
  padding-top: 29px !important;
  padding-right: 0px !important;
  height: 1em !important;
  padding-left: 35px !important;
  padding-right: 35px !important;
  direction: ltr !important;
}
.input-fieldd3 .css-1vbc0rj-MuiInputBase-input-MuiFilledInput-input {
  padding-top: 28px !important;
  padding-right: 0px !important;
  padding-bottom: 16px !important;
  height: 1em !important;

  padding-left: 35px !important;
  padding-right: 35px !important;
  direction: ltr !important;
}
.input-fieldd .css-1vbc0rj-MuiInputBase-input-MuiFilledInput-input {
  padding-top: 28px !important;
  padding-bottom: 16px !important;

  padding-right: 0px !important;
  height: 1em !important;

  /* padding-left: 30px !important; */
}
.direction-ltr {
  direction: ltr !important;
}
.css-1j6omf3 {
  padding-top: 20px !important;
  height: 1.7em !important;
}
.textfield-1
  .css-1u1o83h-MuiAutocomplete-root
  .MuiFilledInput-root
  .MuiAutocomplete-endAdornment {
  left: 20px !important;
  right: unset !important;
}

.css-6y482o-MuiContainer-root {
  margin-top: 0px !important;
}
.footerr .css-1neynur-MuiStack-root > :not(style) + :not(style) {
  margin-top: 0 !important;
  background-color: inherit !important;
}

.css-14l66vr-MuiTypography-root {
  margin-top: 10px !important;
}

.css-145wx9q-MuiAutocomplete-root .MuiFilledInput-root .MuiFilledInput-input {
  padding: 0px !important;
}
.css-145wx9q-MuiAutocomplete-root
  .MuiAutocomplete-inputRoot
  .MuiAutocomplete-input {
  width: 0;
  min-width: auto;
}
.css-59g15k-MuiFormLabel-root-MuiInputLabel-root {
  margin-left: 26px !important;
}
.textfield-1 .css-1goxtxt-MuiInputBase-root-MuiFilledInput-root {
  font-size: 12px !important;
  line-height: 1px !important;
}
.textfield-1 .css-o943dk-MuiFormLabel-root-MuiInputLabel-root {
  right: 26px !important;
  left: unset !important;
  font-size: 12px !important;
  margin-top: -5px !important;
}
.textfield-1 .css-ap58hq-MuiFormControl-root-MuiTextField-root {
  direction: rtl !important;
}
.textfield-1
  .css-1inhg84-MuiAutocomplete-root
  .MuiFilledInput-root
  .MuiFilledInput-input,
.textfield-2
  .css-1inhg84-MuiAutocomplete-root
  .MuiFilledInput-root
  .MuiFilledInput-input {
  padding: 0px !important;
  background-color: red;
}
.textfield-2 .css-o943dk-MuiFormLabel-root-MuiInputLabel-root {
  left: 30px !important;
  margin-top: -5px !important;
  font-size: 12px !important;
  /* margin-left: 10px !important; */
}
.textfield-2 input {
  margin: 0px 0px 1px 0px !important;
  padding-top: 0px !important;
}
.textfield-1 input {
  margin: 0px 0px 1px 0px !important;
}
.css-1yjmyvf-MuiAutocomplete-root .MuiFilledInput-root .MuiFilledInput-input {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.css-1dreu21-MuiAutocomplete-root .MuiFilledInput-root .MuiFilledInput-input {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.css-1goxtxt-MuiInputBase-root-MuiFilledInput-root {
  font-size: 12px !important;
  line-height: 1px !important;
}
.textfield-1 .css-1goxtxt-MuiInputBase-root-MuiFilledInput-root {
  direction: rtl !important;
}
.css-1inhg84-MuiAutocomplete-root .MuiFilledInput-root .MuiFilledInput-input {
  padding: 0px 8px !important;
  font-weight: 700;
}
.css-t6wotx-MuiAutocomplete-root .MuiFilledInput-root .MuiFilledInput-input {
  padding: 0px !important;
  font-weight: 700;
}
.css-1inhg84-MuiAutocomplete-root .MuiFilledInput-root {
  padding-top: 10px !important;
  padding-left: 8px !important;
}

.textfield-1 .css-1inhg84-MuiAutocomplete-root .MuiFilledInput-root {
  direction: rtl !important;
  right: 0 !important;
  left: unset;
}
.textfield-1
  .css-t6wotx-MuiAutocomplete-root
  .MuiFilledInput-root
  .MuiAutocomplete-endAdornment {
  right: unset;
  left: 0;
}

.css-t6wotx-MuiAutocomplete-root
  .MuiFilledInput-root
  .MuiAutocomplete-endAdornment {
  right: unset;
  left: 0;
}
.css-t6wotx-MuiAutocomplete-root
  .MuiFilledInput-root
  .MuiAutocomplete-endAdornment {
  right: 9px;
  direction: ltr !important;
}

.textfield-1 {
  direction: rtl !important;
  right: unset !important;

  border-radius: 13px;
}

.css-1neynur-MuiStack-root > :not(style) + :not(style) {
  margin: 0;
  margin-top: 0px !important;
}

.textfield-1
  .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-47ddn5-MuiInputBase-root-MuiFilledInput-root,
.textfield-2
  .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-47ddn5-MuiInputBase-root-MuiFilledInput-root {
  background: white;
  font-size: 12px !important;
  padding-top: 10px !important;
  padding-right: 32px !important;
}
.css-cxmp86 {
  background-color: none !important;
}

.ar-lang {
  background-color: white !important;
  padding: 10px 0px 9px 0px !important;
  border-radius: 13px !important;

  font-weight: 800;
  margin-left: 10px !important;
}
.eng-lang {
  background-color: white !important;
  padding: 10px 0px 0px 0px !important;
  font-weight: 800;
  border-radius: 13px !important;
  margin-left: 10px !important;
}
.ar-lang .MuiAutocomplete-endAdornment {
  left: 0 !important;
  direction: ltr !important;
  top: 10px !important;
}
.eng-lang .MuiAutocomplete-endAdornment {
  right: 0 !important;
}

.img-select {
  height: 22px !important;
}
.ar-lang .img-select {
  position: absolute;
  right: 0px;
}
.img-select2 {
  height: 22px !important;
  width: unset !important;
}
/* .img-select2 {
  margin-top: 7px !important;
} */

.eng-lang .MuiInputBase-root {
  margin-top: -9px !important;
  padding-left: 5px !important;
  border-radius: 13px !important;
}
.ar-lang .MuiInputBase-root {
  border-radius: 13px !important;
  padding-right: 39px !important;
}
.eng-lang .MuiFilledInput-input {
  padding-left: 8px !important;
  font-weight: 600;
  font-size: 12px;
}
.ar-lang .MuiFilledInput-input {
  font-weight: 600;
  font-size: 12px;
  padding-top: 0px !important;
}
.one .css-1s45jdk {
  padding-left: 0px !important;
}
.one{
  height: 410px !important;
}
.two{
  height: 410px;
}
.css-ap58hq-MuiFormControl-root-MuiTextField-root {
  background-color: white !important;
  border-radius: 20px;
  border-bottom: none;
}
.css-1goxtxt-MuiInputBase-root-MuiFilledInput-root {
  background-color: white !important;
}
.css-1goxtxt-MuiInputBase-root-MuiFilledInput-root {
  background-color: white !important;
}
.textfield-1
  .ar-lang
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1u1o83h-MuiAutocomplete-root
  .MuiFilledInput-root {
  padding: 0 39px 0px 0px !important;
}
.css-1u1o83h-MuiAutocomplete-root {
  margin: 0px !important;
}
.textfield-1
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1u1o83h-MuiAutocomplete-root
  .MuiFilledInput-root {
  padding: 20px !important;
}

.textfield-1
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1u1o83h-MuiAutocomplete-root
  .MuiAutocomplete-inputRoot {
  padding-right: 0px !important;
  font-size: 12px !important;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1u1o83h-MuiAutocomplete-root
  .MuiFilledInput-root {
  padding-right: 33px !important;
  background-color: white;
}

.css-47ddn5-MuiInputBase-root-MuiFilledInput-root:hover {
  background-color: white;
}

.css-1kgnrts {
  margin-top: 20px !important;
  margin-left: 10px;
}

.css-ap58hq-MuiFormControl-root-MuiTextField-root {
  background-color: white !important;
}
.css-1x51fy2 {
  border-radius: 13px !important;
}
.css-13ynqp6-MuiFormControl-root-MuiTextField-root {
  padding: 5px 1px !important;
}
.white {
  border-radius: 13px !important;
}

input:-internal-autofill-selected {
  background-color: none;
}
.autoSelect .css-b06mi3-MuiAutocomplete-root {
  border-radius: 13px !important;
  margin-left: 10px !important;
}
.autoSelect {
  border-radius: 13px !important;
  margin: 3px 0px 0px 0px !important;
}
.autoSelect .css-1dreu21-MuiAutocomplete-root {
  border-radius: 13px !important;
}
.css-8kjmc6 > :not(style) + :not(style) {
  margin: 0px 0px 0px !important;
}
.footerr .css-7zfb0w {
  margin: 10px 0px !important;
  background-color: inherit !important;
}
.receiptt .css-1qsxih2 {
  margin-bottom: 10px !important;
}
.css-1u19dx9-MuiStack-root > :not(style) + :not(style) {
  margin: 0;
  margin-top: 0px !important;
}

/* my dropdown  */
.expand-icon
  .MuiAutocomplete-endAdornment.css-1q60rmi-MuiAutocomplete-endAdornment {
  right: 5px !important;
}
.expand-icon button svg {
  display: none;
}
.expand-icon button::after {
  content: "" !important;
  width: 14px !important;
  height: 13px !important;
  font-weight: bolder !important;
  display: block !important;
  background-image: url(./assets/Vector.svg) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  /* margin: 11px 27px !important; */
}
.expand-icon button {
  width: 40px !important;
  height: 40px !important;
  margin-right: 10px !important;
}
.expand-icon .css-uge3vf::after {
  content: "";
  width: 14px !important;
  height: 13px !important;
  font-weight: bolder !important;
  display: block !important;
  background-image: url(./assets/Vector.svg) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  /* margin: 11px 27px !important; */
}
.expand-icon .css-vubbuv::after {
  content: "";
  width: 12px;
  height: 15px;
  font-weight: bolder;
  display: block;
  background-image: url(./assets/Vector.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 11px 27px;
}
label {
  color: "rgba(173, 180, 192, 1)";
}
.expand-icon
  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
  display: none;
}
/*Arabic */
.expand-icon1
  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
  display: none;
}
.expand-icon1
  .MuiAutocomplete-endAdornment.css-1q60rmi-MuiAutocomplete-endAdornment {
  right: 5px !important;
}
.expand-icon1 button svg {
  display: none;
}
.expand-icon1 button::after {
  content: "" !important;
  width: 14px !important;
  height: 13px !important;
  font-weight: bolder !important;
  display: block !important;
  background-image: url(./assets/Vector.svg) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  /* margin: 11px 27px !important; */
}
.expand-icon1 button {
  width: 40px !important;
  height: 40px !important;
  margin-left: 15px !important;
}
.expand-icon1 .css-uge3vf::after {
  content: "";
  width: 14px !important;
  height: 13px !important;
  font-weight: bolder !important;
  display: block !important;
  background-image: url(./assets/Vector.svg) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  /* margin: 11px 27px !important; */
}
.expand-icon1 .css-vubbuv::after {
  content: "";
  width: 12px;
  height: 15px;
  font-weight: bolder;
  display: block;
  background-image: url(./assets/Vector.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 11px 27px;
}
.css-1xit39d-MuiContainer-root {
  padding-left: 15px !important;
  padding-right: 12px !important;
}

.container-padding .one {
  padding-right: 0px !important;
}
.popupmodal > .container-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.css-1qd9dyb {
  padding-left: 15px;
  padding-right: 13px;
}
.expand-icon .css-edpqz1 {
  display: none !important;
}
.css-edpqz1 {
  display: none !important;
}

.css-16rg3dz > :not(style) + :not(style) {
  margin: 0px 0px 0px !important;
}
.css-1yxchwy {
  padding-left: 0px !important;
}

.tags-width{
  width: 109px;
}

.slots-grid{
  padding: 12px 16px;
  justify-content: space-between; 
  column-gap: 1rem;
  align-items: center;
  /* padding: 12px 14px 12px 16px;
    column-gap: 21px; */
}
@media (max-width: 576px) {
  .one,.two{
    height: calc(100vh - 200px ) !important;
  }
  .css-52biud{
    padding:10px 10px 100px !important
  }
  /* .tags-width{
    width: 95px !important;
  } */
  .css-x402zf-MuiPaper-root-MuiDialog-paper{
    max-height: auto !important;
  }
  .css-hz1bth-MuiDialog-container{
    background-color: #FAFAFA !important;
    opacity: 100% !important;
  }
  .css-ekeie0{
    background-color: #FAFAFA !important;
  }
  .css-j12x82{
    box-shadow: none !important;
  }
  .css-x402zf-MuiPaper-root-MuiDialog-paper{
    box-shadow: none !important;
  }
  .input-fielddar .css-1vbc0rj-MuiInputBase-input-MuiFilledInput-input{
    padding-left: 50%;
  }
  .input-fielddar .css-1j6omf3{
    padding-left: 53%;
  }
  .popupmodal .MuiDialog-paper {
    height: 100% !important;
    max-height: -webkit-fill-available !important;
  }
  .textbox2 svg {
    padding: "20px";
  }
  .bookingbtn-m {
    margin-top: 12px !important;
  }
  .margin-b {
    margin-bottom: 2px !important;
  }
  .text-icon {
    padding-top: 13px !important;
    padding-left: 21px !important;
    padding-right: 14px !important;
  }
  .text-icon2 {
    padding-top: 13px !important;
    padding-right: 20px !important;
    padding-left: 14px !important;
  }
  .textfield-1 img {
    position: absolute !important;
    right: 0px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .input-fieldd3 .css-1j6omf3 {
    padding-top: 30px !important;
  }
  .input-fielddar .css-1j6omf3 {
    direction: ltr !important;
    height: 1em !important;
    padding: 30px 0 16px 53% !important;
  }
  .css-z5c1wl::before {
    left: 169px !important;
  }
}
.css-leu2uh {
  background-color: white !important;
}
.eng-lang .css-1rgmeur {
  padding: 0px 39px !important;
  top: -6px !important;
  font-size: 15px !important;
}
.css-16tso1w .MuiFilledInput-root .MuiFilledInput-input {
  padding-top: 3px !important;
}
.css-16tso1w .MuiFilledInput-root {
  background-color: white !important;
}
.css-lvcx3lc {
  background-color: white !important;
}
.ar-lang .css-1rgmeur {
  /* padding: 0px 20px !important;
  margin-left: 30px !important; */
  right: 29px !important;
  left: unset !important;
  top: -8px !important;
  color: "rgba(173, 180, 192, 1)";
}
.css-lvcx3lc {
  background-color: white !important;
}
.css-1rgmeur.Mui-focused {
  color: rgba(173, 180, 192, 1) !important;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-16tso1w
  .MuiFilledInput-root {
  padding-right: 35px !important;
}
.css-1rgmeur {
  font-family: "Cairo" !important;
  color: rgba(173, 180, 192, 1) !important;
}
.css-eqj1il-MuiGrid-root{
  max-width: 100% !important;
}
.css-jht6zw-MuiGrid-root{
  gap: 8px !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
}
.css-f01g7c{
  max-width: 100% !important;
  
}
.css-11pzva1{
  gap: 1rem !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  justify-content: center !important;
}
@media only screen and (max-width: 900px) {
  /* .tags-width{
    width: 95px !important;
  } */
}

@media only screen and (max-width: 768px) {
  /* .tags-width{
    width: 95px !important;
  } */
  .cal-ender1 {
    padding: 0px !important;
  }
  .cal-ender2 {
    padding: 0px !important;
    max-width: 100% !important;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-4620jz
    .MuiFilledInput-root {
    padding-right: 45px;
  }
  .css-1xaekgw {
    margin: 10px 0px 20px 0px !important;
  }

  .css-mju1p9-MuiFormLabel-root-MuiInputLabel-root {
    right: 50px;
  }
  .bookingbtn-m {
    margin-top: 12px !important;
  }
  .margin-b {
    margin-bottom: 2px !important;
  }
  /* .textfield-1 .img-select img {
    position: absolute !important;
    right: 0px !important;
  } */
  .img-select {
    position: absolute !important;
    right: 20px !important;
    margin-left: 10px !important;
    padding-right: 10px !important;
  }
  .img-select2 {
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-right: 10px !important;
  }
  .ar-lang,
  .eng-lang {
    margin-left: 0px !important;
  }
  .textfield-1 .css-o943dk-MuiFormLabel-root-MuiInputLabel-root {
    padding-right: 10px !important;
    font-size: 12px !important;
  }
  .ar-lang input {
    padding-right: 10px !important;
  }
  .textfield-1
    .css-1dreu21-MuiAutocomplete-root
    .MuiFilledInput-root
    .MuiFilledInput-input {
    padding-right: 10px !important;
  }
  .textfield-1
    .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-47ddn5-MuiInputBase-root-MuiFilledInput-root {
    padding-right: 46px !important;
  }
  .textfield-2 .css-o943dk-MuiFormLabel-root-MuiInputLabel-root {
    margin-left: 10px !important;
    font-size: 12px !important;
  }
  .css-1dreu21-MuiAutocomplete-root .MuiFilledInput-root .MuiFilledInput-input {
    padding-top: 7px !important;
  }
  .css-lyjmyvf-MuiAutocomplete-root .MuiFilledInput-root .MuiFilledInput-input {
    padding-top: 7px !important;
  }
  .textfield-1
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-dgs5am
    .MuiFilledInput-root,
  .textfield-2
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-dgs5am
    .MuiFilledInput-root {
    background-color: white !important;
  }
  .MuiAutocomplete-hasPopupIcon.css-dgs5am .MuiFilledInput-root {
    background-color: white !important;
  }
  .expand-icon .css-uge3vf::after {
    content: "";
    width: 12px;
    height: 15px;
    font-weight: bolder;
    display: block;
    background-image: url(./assets/Vector.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 11px 10px;
  }
  .eng-lang .css-1rgmeur {
    padding: 0px 20px !important;
    margin-left: 26px !important;
    top: -5px !important;
  }
  .ar-lang .css-1rgmeur {
    padding: 0px 8px !important;
    margin-left: 30px !important;
    /* right: 29px !important; */
    top: -3px !important;
    left: unset !important;
    color: "rgba(173, 180, 192, 1)";
  }
  .textfield-2 .css-dgs5am .MuiFilledInput-root .MuiFilledInput-input {
    padding: 3px 0px !important;
  }
  .textfield-1 .css-dgs5am .MuiFilledInput-root .MuiFilledInput-input {
    padding: 3px 0px !important;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-dgs5am
    .MuiFilledInput-root {
    padding-right: 45px !important;
  }
  .css-dgs5am .MuiFilledInput-root .MuiFilledInput-input {
    padding: 0px 4px !important;
  }
  .css-lvcx3lc.Mui-focused {
    background-color: white !important;
  }
  .autoSelect {
    margin: 10px 0px !important;
  }
  .ar-lang .MuiInputBase-root {
    padding-right: 40px !important;
  }
  .eng-lang .MuiFilledInput-input {
    padding-top: 3px !important;
    padding-left: 0px !important;
  }
  .ar-lang .MuiFilledInput-input {
    padding-top: 4px !important;
  }
}
.css-lvcx3lc.Mui-focused {
  background-color: white !important;
}
.css-1vcx3lc {
  background-color: white !important;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1wk4fjg
  .MuiFilledInput-root {
  padding-right: 38px !important;
  padding-top: 13px !important;
}
/* .container .css-1xit39d-MuiContainer-root {
  padding: 0 !important;
} */
.css-1j6omf3 {
  padding-right: 0px !important;
}

.hide-date {
  display: none;
}

.react-calendar__navigation button:disabled {
  background-color: white !important;
}

.css-1j6omf3 {
  padding-bottom: 18px !important;
}

.css-1nlkki7-MuiStack-root {
  padding: 0px !important;
}

.css-1o2n4nu-MuiInputBase-input-MuiFilledInput-input.Mui-disabled {
  -webkit-text-fill-color: black !important;
  opacity: 1 !important;
}
.next-month-date {
  color: blue !important;
}

.react-calendar__tile--next-month {
  /* Styles for next month dates */
  color: red !important;
  background-color: yellow !important;
}

.blue-dates {
  color: rgba(8, 79, 140, 1) !important;
}
.gray-dates {
  color: rgba(192, 191, 193, 1) !important;
}
.css-1g7d3df {
  color: black !important;
}

.css-jpbqk9 {
  width: 1.5em !important;
  height: 2em !important;
}

.css-1agnrrp:hover {
  background-color: white !important;
}
.css-1g7d3df.Mui-disabled {
  /* opacity: 1; */
  -webkit-text-fill-color: black !important;
}
.checkbox-ctrl .css-133usgr {
  padding-right: 4px !important;
}
.checkbox-ctrl .css-1jaw3da {
  margin-right: 0px !important;
}

.css-1jaw3da {
  margin-right: 0px !important;
}
.css-5oc58s{
  padding-top: 5px;
} 
.input-fieldd3 {
  position: relative !important;
  
}
/* .tags-padding{
  padding: 10px 14px 100px 22px !important
} */
/* .tags-width{
  width: 109px;
} */
/* .tags-column-gap{
  column-gap: 1rem !important;
} */
.terms-hover:hover{
  cursor: pointer;
  color: blue;

}
.react-calendar__navigation button {
  min-width: 90px !important;
}
.css-j204z7-MuiFormControlLabel-root {
  margin-right: 0px !important;
}
.css-zt4aae-MuiFormLabel-root-MuiInputLabel-root {
left: 10px !important;
top: 20px !important;
font-size: 12px !important;
}
.css-1omlvr-MuiFormLabel-root-MuiInputLabel-root{
  font-size: 12 px !important;
  left: 10px !important;
top: 20px !important;
}
.css-luvhte-MuiFormLabel-root-MuiInputLabel-root {
  left: 10px !important;
  top: 20px !important;
  font-size: 16px !important;
}


/* .textareainput:focus{
  font-size: 12px !important;
}
.textareainput{
  font-size: 1rem !important;
} */
@media  (max-width:1000px){
  /* .tags-padding{
    padding: 10px 7px 100px 16px !important

  } */}
  @media  (max-width:600px){
    /* .tags-padding{
      padding: 10px 7px 100px 20px !important
  
    } */
  } 
 

  @media  (max-width:480px){
    .one,.two{
      height: calc(100vh - 223px ) !important;
    }
    .tags-width{
        width: 100px !important;
      }
  }
 
    @media (max-width:380px){
      .tags-width{
        width: 95px !important;
      }
      .slots-grid{
        padding: 12px 10px;
       
      }
    }
    @media  (max-width:350px){
      .tags-width{
        width: 87px !important;
      }
    }
    @media (max-width:1024px){
     
      .slots-grid{
      
        column-gap: 5px;
      }
    }
    @media (max-width:635px){
       .tags-width{
        width: 98px ;
      }
     .slots-grid{
column-gap: 10px;
     }
    }
    @media  (max-width:360px){
      .tags-width{
        width: 80px !important;
      }
     
     
      } 
    @media (max-width:960px){
      /* .tags-width{
        width: 98px !important;
      } */
      /* .slots-grid{
      
        padding: 12px 22px 12px 23px !important;
          column-gap: 11px !important;
      } */
    }



